<template>
  <div class="card-style mx-0 mt-5">
    <DivLoader
      v-if="loading"
      :loading_type="'list-item-two-line,list-item-two-line,list-item-two-line'"
    ></DivLoader>
    <div v-else>
      <!-- <p class="font-bold fs-18 secodry-text-3 pb-3">
        {{ $lang.AADHAAR_DETAILS }}
      </p> -->
      <!--     empty state  -->
      <p v-if="!name" class="label-text text-center">
        {{ $messages.AADHAAR_DETAILS_NOT_FOUND }}
      </p>
      <div v-if="name">
        <div class="d-flex">
          <div>
            <v-checkbox
              :disabled="true"
              label="Need Manual Aadhaar Verification"
              v-model="aadhaar_manual_verification"
            ></v-checkbox>
          </div>
          <div>
            <v-checkbox
              :disabled="true"
              label="Need Manual Pan Verification"
              v-model="pan_manual_verification"
            ></v-checkbox>
          </div>
        </div>
        <v-row>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.AADHAAR }}</p>
            <p class="secodry-text-3">{{ aadhaar }}</p>
          </v-col>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.NAME }} on Aadhaar</p>
            <p class="secodry-text-3">{{ name }}</p>
          </v-col>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.GENDER }}</p>
            <p class="secodry-text-3">{{ gender }}</p>
          </v-col>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.DATE_OF_BIRTH }}</p>
            <p class="secodry-text-3">{{ dob }}</p>
          </v-col>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.ADDRESS }}</p>
            <p class="secodry-text-3">{{ address }}</p>
          </v-col>
          <v-col class="py-2" cols="12" md="4">
            <p class="secodry-text-2">{{ $lang.CITY }}</p>
            <p v-if="city" class="secodry-text-3">{{ city }} ,{{ state }}</p>
            <p v-else class="secodry-text-3">-</p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
  },
  data() {
    return {
      loading: false,
      id: "",
      aadhaar: "",
      name: "",
      gender: "",
      dob: "",
      address: "",
      city: "",
      state: "",
      pan_manual_verification: "",
      aadhaar_manual_verification: "",
    };
  },
  created() {
    this.getAadhaarDetail();
  },
  methods: {
    /* fetching the customer list form server*/
    getAadhaarDetail() {
      const self = this;
      self.loading = true;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.aadhaar = response.data.aadhaar;
          self.name = response.data.name;
          self.gender = response.data.gender;
          self.dob = response.data.date_of_birth;
          self.address = response.data.address;
          self.city = response.data.city;
          self.state = response.data.state;
          self.pan_manual_verification =
            response.data.is_pan_need_manual_verified;
          self.aadhaar_manual_verification =
            response.data.is_aadhaar_need_manual_verified;
        }
      };
      const finallyHandler = () => {
        self.loading = false;
      };
      self.request_GET(
        self,
        self.$urls.GET_AADHAAR_DATA,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>